
  import { defineComponent, onMounted, ref } from 'vue'
  import { useRoute,useRouter } from 'vue-router'
  import { api } from '@/api/useAxios'
  import { getUserId } from '@/utils/auth'
  import { changeShareInfo } from '@/utils/wechat'
  import { timeFormat } from '@/utils/util'

  export default defineComponent({
    name: 'Share',
    setup: () => {
      const route = useRoute()
      const router = useRouter()
      const { dailyWorkId } = route.params
      const showSelf = ref(false)
      const show = ref(true);
      const homework = ref<any>()
      const qrywork = () => {
        api.post('/mmdailywork/getDailyWorkDetail', {
          dailyWorkId: dailyWorkId
        }).then((r) => {
          homework.value = r
          if(r.userId === Number(getUserId())){
            showSelf.value = true
          }
          console.log(window.location.href)
          changeShareInfo({
            title: '每日打卡:' + r.userName,
            desc: r.detailDesc ? r.detailDesc : '无练习，不学习',
            link: window.location.href,
            imgUrl: r.userAvatar
          })
        })
      }
      qrywork()

      const imge = require('@/assets/imgs/share.png')

      return {
        showSelf,
        homework,
        timeFormat,
        watchmore: () => {
          router.push('/daily')
        },
        show,
        imge
      }
    }
  })
